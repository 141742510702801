module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.22.0_gatsby@4.22.0_@types+webpack@5.28.5_babel-eslint@10.1.0_eslint@_fn3wmxwohmhvwotchga3dbvcpu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Multiply AI","short_name":"Multiply","start_url":"/","icon":"src/images/icon-96x96.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f6b94f83159276f431cb919188ac858"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.22.0_@types+webpack@5.28.5_babel-eslint@10.1.0_eslint@8.41.0__encoding@0.1.13_react-_zx7kjszd2uyahecsmroia5tni4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
