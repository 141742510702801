exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-business-ops-tsx": () => import("./../../../src/pages/business-ops.tsx" /* webpackChunkName: "component---src-pages-business-ops-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-digital-financial-advice-tsx": () => import("./../../../src/pages/digital-financial-advice.tsx" /* webpackChunkName: "component---src-pages-digital-financial-advice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intelligence-tsx": () => import("./../../../src/pages/intelligence.tsx" /* webpackChunkName: "component---src-pages-intelligence-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

